import React, { useContext, useState } from "react";
import { Menu } from "antd";
import {
	FileOutlined,
	ContainerOutlined,
	CalendarOutlined,
	FileDoneOutlined,
	ProfileOutlined,
	FormOutlined,
	FileProtectOutlined,
	FileTextOutlined,
	EditOutlined,
	FolderOpenOutlined,
	FileSearchOutlined
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { SecurityContext } from "../../../../context/SecurityContextProvider";
import { VersionApiColaborador } from "../../../../enum/VersionApiColaborador";
import LoginService from "services/LoginService";

export function MenuPrincipal({ opciones }) {
	const page = window.location.pathname.split("/")[1];
	const { getVersionApiColaborador } = useContext(SecurityContext);
	const [menuActivo, setMenuActivo] = useState(page === "mis-documentos" ? "bandejaEntrada" : page);
	const isAceros = LoginService.getUsuarioAutenticado()
		.empresaSeleccionada.razonSocial.toUpperCase()
		.includes("ACEROS CHILCA SAC")
		? true
		: false;
	const tieneTareasPendientes = LoginService.getUsuarioAutenticado().empresaSeleccionada.colaborador.tareasPendientes;

	console.log("XXXXXXXX", LoginService.getUsuarioAutenticado().empresaSeleccionada.colaborador.tieneAccesoOpcionConsultaDocumentoPortalColaborador);
	let history = useHistory();

	function handleOnClick(e) {
		switch (e.key) {
			case "portal-colaborador.mis-documentos":
				history.push("/mis-documentos");
				break;

			case "portal-colaborador.mis-formularios":
				history.push("/mis-formularios");
				break;

			case "portal-colaborador.v3.mis-pendientes":
				history.push("/v3/mis-pendientes");
				break;

			case "portal-colaborador.v3.mis-documentos":
				history.push("/v3/mis-documentos");
				break;

			case "bandejaEntrada":
				setMenuActivo(e.key);
				if (getVersionApiColaborador() === VersionApiColaborador.v1) {
					history.push("/bandejaEntrada");
					break;
				} else {
					history.push("/mis-documentos");
					break;
				}

			case "bandejaConsulta":
				setMenuActivo(e.key);
				history.push("/bandejaConsulta");
				break;

			case "portal-colaborador.vacaciones":
				setMenuActivo(e.key);
				history.push("/vacaciones/solicitudes");
				break;

			case "bandejaEvaluacion":
				setMenuActivo(e.key);
				history.push("/bandejaEvaluacion");
				break;

			case "portal-colaborador.solicitud-personal":
				setMenuActivo(e.key);
				history.push("/solicitud-personal");
				break;

			case "portal-colaborador.entrevistas":
				setMenuActivo(e.key);
				history.push("/entrevistas");
				break;

			case "portal-colaborador.tareas-pendientes":
				setMenuActivo(e.key);
				history.push("/tareas-pendientes");
				break;

			case "portal-colaborador.renovacion-contratos":
				setMenuActivo(e.key);
				history.push("/contratos/renovacion");
				break;

			case "portal-colaborador.solicitud-documentos":
				setMenuActivo(e.key);
				history.push("/solicitud-documentos");
				break;

			case "portal-colaborador.consulta-documentos":
				setMenuActivo(e.key);
				history.push("/consulta-documentos");
				break;
			
			default:
				console.error("No existe ningún item con el key: ", e.key);
		}
	}

	function tieneAcceso(codigo) {
		return opciones?.includes(codigo);
	}

	function mostrarMenuItem(codigo, nombre, icono) {
		if (tieneAcceso(codigo)) {
			return (
				<Menu.Item key={codigo} icon={icono}>
					{nombre}
				</Menu.Item>
			);
		}
		return null;
	}

	return (
		<Menu mode="horizontal" onClick={handleOnClick} defaultSelectedKeys={menuActivo}>
			{mostrarMenuItem("bandejaEntrada", "Mis Documentos", <FileOutlined />)}
			{mostrarMenuItem("portal-colaborador.mis-documentos", "Mis Documentos", <FileOutlined />)}
			{mostrarMenuItem("portal-colaborador.v3.mis-pendientes", "Documentos Pendientes", <EditOutlined />)}
			{mostrarMenuItem("portal-colaborador.v3.mis-documentos", "Mis Documentos", <FolderOpenOutlined />)}
			{mostrarMenuItem("bandejaConsulta", "Buzón de Consultas", <ContainerOutlined />)}
			{mostrarMenuItem("bandejaEvaluacion", "Evaluación de Desempeño", <FileDoneOutlined />)}
			{mostrarMenuItem("portal-colaborador.vacaciones", "Ausencias y Vacaciones", <CalendarOutlined />)}
			{mostrarMenuItem("portal-colaborador.solicitud-personal", "Solicitud Personal", <FileOutlined />)}
			{mostrarMenuItem("portal-colaborador.entrevistas", "Entrevistas", <ProfileOutlined />)}
			{tieneTareasPendientes &&
				mostrarMenuItem("portal-colaborador.tareas-pendientes", "Tareas Pendientes", <FormOutlined />)}

			{(LoginService.getUsuarioAutenticado().empresaSeleccionada.colaborador.administradorObra ||
				LoginService.getUsuarioAutenticado().empresaSeleccionada.colaborador.jefeProyecto) &&
				mostrarMenuItem(
					"portal-colaborador.renovacion-contratos",
					"Renovación Contratos",
					<FileProtectOutlined />
				)}
			{isAceros &&
				!LoginService.getUsuarioAutenticado()
					.empresaSeleccionada.colaborador.cargoNombre.toUpperCase()
					.includes("PRACTICANTE") &&
				mostrarMenuItem(
					"portal-colaborador.solicitud-documentos",
					"Solicitud Documentos",
					<FileTextOutlined />
				)}

			{!isAceros &&
				mostrarMenuItem(
					"portal-colaborador.solicitud-documentos",
					"Solicitud Documentos",
					<FileTextOutlined />
				)}

			{LoginService.getUsuarioAutenticado().empresaSeleccionada.colaborador.tieneAccesoOpcionConsultaDocumentoPortalColaborador &&
				mostrarMenuItem("portal-colaborador.consulta-documentos", "Consulta Documentos", <FileSearchOutlined />)}
		</Menu>
	);
}
